<template>
  <div class="hold-transition">
    <div class="row" style="position: relative; z-index: auth">
      <div class="table-responsive col-md-12">
        <table
          class="table table-bordered table-striped table-hover table-sm team-roster-table text-nowrap pb-5"
        >
          <thead class="bg-dark text-center">
            <tr class="text-center">
              <th>
                Acciones
                <button
                  type="button"
                  class="btn btn-xs bg-primary ml-2"
                  @click="addDetSolicitud()"
                  disabled
                >
                  <i class="fas fa-plus"></i>
                </button>
              </th>
              <th>Empresa</th>
              <th>Tipo Servicio</th>
              <th>Tipo Vehículo / Equipo</th>
              <th>Turno</th>
              <th>Cantidad Vehículos a Solicitar</th>
              <th>Tiempo Requerido del Servicio <small>(Días)</small></th>
              <th>Descripción del Material o Equipos</th>
              <th>Cantidad Material</th>
              <th>Largo x Ancho x Alto <small>(Mts)</small></th>
              <th>Peso <small>(Kg)</small></th>
              <th>Radio de Izaje <small>(Mts)</small></th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="(detSoli, index) in detSolicitudes" :key="index">
              <td class="text-center">
                <div class="btn-group" v-if="estado_det_solicitud != 2">
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    :disabled="estado_det_solicitud != -1"
                    @click="editDetSolicitud(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div
                  class="btn-group"
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <button
                    type="button"
                    class="btn btn-sm bg-success"
                    v-show="!$v.detSolicitud.$invalid"
                    @click="saveUpdateDetSolicitud(index)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button
                    class="btn btn-sm bg-danger"
                    @click="cancelDetSolicitud()"
                  >
                    <i class="fas fa-ban"></i>
                  </button>
                </div>
                <div
                  class="btn-group"
                  v-if="
                    estado_det_solicitud == 2 && id_actualizar_det !== index
                  "
                >
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    disabled
                    @click="editDetSolicitud(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
              </td>
              <td
                class="text-center"
                :class="
                  detSoli.empresa_id !== null
                    ? 'table-success'
                    : 'table-warning'
                "
              >
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <v-select
                    :class="[
                      $v.detSolicitud.empresa_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="detSolicitud.empresa"
                    placeholder="Empresa"
                    label="razon_social"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.empresas"
                    @input="selectEmpresa()"
                    style="min-width: 100px"
                  >
                  </v-select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detSoli.empresa !== null && detSoli.empresa !== undefined
                    "
                  >
                    {{ detSoli.empresa.razon_social }}
                  </div>
                  <div v-else></div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <select
                    id="tipo_servicio"
                    class="form-control form-control-sm col-12"
                    v-model="detSolicitud.tipo_servicio_obj"
                    :class="
                      $v.detSolicitud.tipo_servicio_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    style="width: 150px"
                    @change="changeTipoServ()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_servicio in listasForms.tipos_servicios"
                      :key="tipo_servicio.numeracion"
                      :value="tipo_servicio"
                    >
                      {{ tipo_servicio.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detSoli.tipo_servicio_obj !== undefined &&
                        detSoli.tipo_vh_equi_obj !== null
                    "
                  >
                    {{ detSoli.tipo_servicio_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ detSoli.tipoServicio }}
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <div v-if="detSoli.tipo_servicio_obj.numeracion == 1">
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm col-12"
                      v-model="detSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.detSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      style="width: 15em"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                        :key="tipo_vehiculo.id"
                        :value="tipo_vehiculo"
                      >
                        {{ tipo_vehiculo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div v-if="detSoli.tipo_servicio_obj.numeracion == 2">
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm col-12"
                      v-model="detSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.detSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_equipo in listasForms.tipos_equipos"
                        :key="tipo_equipo.numeracion"
                        :value="tipo_equipo"
                      >
                        {{ tipo_equipo.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detSoli.tipo_vh_equi_obj !== undefined &&
                        detSoli.tipo_vh_equi_obj !== null
                    "
                  >
                    <div v-if="detSoli.tipo_servicio_obj.numeracion == 1">
                      {{ detSoli.tipo_vh_equi_obj.nombre }}
                    </div>
                    <div v-else>
                      {{ detSoli.tipo_vh_equi_obj.descripcion }}
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="detSoli.tipo_servicio == 1">
                      {{ detSoli.tipoVh }} - {{ detSoli.tipoRemolque }}
                    </div>
                    <div v-else>
                      {{ detSoli.tipoEquipo }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <select
                    id="turno"
                    class="form-control form-control-sm"
                    v-model="detSolicitud.turno_obj"
                    :class="
                      $v.detSolicitud.turno_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="turno in listasForms.turnos"
                      :key="turno.id"
                      :value="turno"
                    >
                      {{ turno.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detSoli.turno_obj !== undefined &&
                        detSoli.turno_obj !== null
                    "
                  >
                    {{ detSoli.turno_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ detSoli.nTurno }}
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="number"
                    class="form-control form-control-sm col-12"
                    id="cantidad_vehiculos"
                    v-model="detSolicitud.cantidad_vh_equi"
                    :class="
                      $v.detSolicitud.cantidad_vh_equi.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>
                  {{ detSoli.cantidad_vh_equi }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm col-12"
                    id="tiempo_serv"
                    v-model="detSolicitud.tiempo_serv"
                    :class="
                      $v.detSolicitud.tiempo_serv.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ detSoli.tiempo_serv }}</div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm col-12"
                    id="descripcion_material"
                    v-model="detSolicitud.descripcion_material"
                    :class="
                      $v.detSolicitud.descripcion_material.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>
                  {{ detSoli.descripcion_material }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="number"
                    class="form-control form-control-sm col-12"
                    id="cantidad_material"
                    v-model="detSolicitud.cantidad_material"
                    :class="
                      $v.detSolicitud.cantidad_material.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ detSoli.cantidad_material }}</div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm col-12"
                    id="medidas"
                    v-mask="{
                      mask: '### X ### X ###',
                    }"
                    v-model="detSolicitud.medidas"
                    :class="
                      $v.detSolicitud.medidas.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ detSoli.medidas }}</div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="number"
                    class="form-control form-control-sm col-12"
                    id="peso"
                    v-model="detSolicitud.peso"
                    :class="
                      $v.detSolicitud.peso.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    style="width: 8em"
                  />
                </div>
                <div v-else>{{ detSoli.peso }}</div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    v-if="
                      detSolicitud &&
                        detSolicitud.tipo_servicio_obj &&
                        detSolicitud.tipo_servicio_obj.numeracion == 2
                    "
                    type="number"
                    class="form-control form-control-sm col-12"
                    id="radio_izaje"
                    v-model="detSolicitud.radio_izaje"
                    :class="
                      $v.detSolicitud.radio_izaje.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ detSoli.radio_izaje }}</div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm col-12"
                    id="observaciones"
                    v-model="detSolicitud.observaciones"
                    :class="
                      $v.detSolicitud.observaciones.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    style="width: 15em"
                  />
                </div>
                <div v-else>{{ detSoli.observaciones }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="col-md-12 text-center">
          <h6>
            <span class="badge badge-warning"
              ><i class="fas fa-times-circle"></i> Por Favor asignar empresa de la solicitud</span
            >
          </h6>
        </div> -->
        <div class="col-md-12 text-center">
          <h6
            v-if="detSolicitudes.some((detalle) => detalle.empresa_id == null)"
          >
            <span class="badge badge-warning">
              <i class="fas fa-times-circle"></i> Por favor asignar empresa de
              la solicitud
            </span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "CsSolicitudesInternasDetSolicitud",
  components: {
    vSelect,
  },
  data() {
    return {
      detSolicitudes: [],
      detSolicitud: [],
      validaSave: false,
      listasForms: {
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
        turnos: [],
      },
      estado_det_solicitud: -1,
      id_actualizar_det: -1,
      detSolicitudAnt: null,
    };
  },

  validations() {
    if (
      this.detSolicitud.tipo_servicio_obj &&
      this.detSolicitud.tipo_servicio_obj.numeracion == 1
    ) {
      // Formulario para transporte
      return {
        detSolicitud: {
          empresa_id: {
            required,
          },
          tipo_servicio_obj: {
            required,
          },
          tipo_vh_equi_obj: {
            required,
          },
          turno_obj: {
            required,
          },
          cantidad_vh_equi: {
            required,
          },
          tiempo_serv: {
            required,
          },
          descripcion_material: {
            required,
          },
          cantidad_material: {
            required,
          },
          medidas: {
            required,
          },
          peso: {
            required,
          },
          observaciones: {
            required,
          },
        },
      };
    } else if (
      this.detSolicitud.tipo_servicio_obj &&
      this.detSolicitud.tipo_servicio_obj.numeracion == 2
    ) {
      // Formulario para izajes
      return {
        detSolicitud: {
          empresa_id: {
            required,
          },
          tipo_servicio_obj: {
            required,
          },
          tipo_vh_equi_obj: {
            required,
          },
          turno_obj: {
            required,
          },
          cantidad_vh_equi: {
            required,
          },
          tiempo_serv: {
            required,
          },
          descripcion_material: {
            required,
          },
          cantidad_material: {
            required,
          },
          medidas: {
            required,
          },
          peso: {
            required,
          },
          radio_izaje: {
            required,
          },
          observaciones: {
            required,
          },
        },
      };
    } else {
      return {
        detSolicitud: {
          empresa_id: {
            required,
          },
          tipo_servicio_obj: {
            required,
          },
          tipo_vh_equi_obj: {
            required,
          },
          turno_obj: {
            required,
          },
          cantidad_vh_equi: {
            required,
          },
          tiempo_serv: {
            required,
          },
          descripcion_material: {
            required,
          },
          cantidad_material: {
            required,
          },
          medidas: {
            required,
          },
          peso: {
            required,
          },
          observaciones: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async getDetSolicitudes() {
      await axios
        .get("/api/cs/asignacionesSolicitudesInternas/detSolicitudesInternas", {
          params: { solicitudInterna_id: this.$parent.form.id },
        })
        .then(async (response) => {
          this.detSolicitudes = response.data;
          for (let i = 0; i < this.detSolicitudes.length; i++) {
            this.detSolicitudes[i].medidas =
              this.detSolicitudes[i].largo +
              "X" +
              this.detSolicitudes[i].ancho +
              "X" +
              this.detSolicitudes[i].alto;
          }
          //await this.llenarDetSolicitud();
        });
    },

    async getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.detSolicitud.empresa_id = null;
      if (
        this.detSolicitud.empresa !== null &&
        this.detSolicitud.empresa !== undefined
      ) {
        this.detSolicitud.empresa_id = this.detSolicitud.empresa.id;
      }
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getTipoVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          //me.listasForms.tipos_vehiculos = respuesta;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              tipo: "tv",
              tipo_vehi: respuesta[i].id,
              nombre: respuesta[i].nombre,
            });
          }

          axios
            .get("/api/lista/187", {
              params: {
                cod_alterno: 4,
              },
            })
            .then(function(response) {
              let respuestaR = response.data;
              for (let i = 0; i < respuestaR.length; i++) {
                lista.push({
                  id: respuestaR[i].numeracion,
                  tipo: "tr",
                  tipo_vehi: 1,
                  nombre: "CABEZOTE - " + respuestaR[i].descripcion,
                });
              }
            })
            .catch(function(error) {});

          me.listasForms.tipos_vehiculos = lista;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoEquipo() {
      await axios.get("/api/lista/129").then((response) => {
        this.listasForms.tipos_equipos = response.data;
      });
    },

    changeTipoServ() {
      this.detSolicitud.tipo_vh_equi_obj = null;
    },

    /*async llenarDetSolicitud() {
      this.detSolicitudes.forEach(async (element) => {
        element.tipo_servicio_obj = this.listasForms.tipos_servicios.filter(
          (item) => {
            if (item.numeracion == element.tipo_servicio) {
              return item;
            }
          }
        )[0];

        element.turno_obj = this.listasForms.turnos.filter((item) => {
          if (item.numeracion == element.turno) {
            return item;
          }
        })[0];

          if (element.tipo_servicio == 1) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.filter(
            (item) => {
              if (
                item.tipo_vehi == element.tipo_vh_equi &&
                element.tipo_remolque == item.id
              ) {
                let tv = {};
                if (element.tipo_remolque !== null) {
                  tv = {
                    id: item.numeracion,
                    tipo: "tr",
                    tipo_vehi: 1,
                    nombre: "CABEZOTE - " + item.descripcion,
                  };
                } else {
                  tv = {
                    id: item.id,
                    tipo: "tv",
                    tipo_vehi: item.numeracion,
                    nombre: item.nombre,
                  };
                }

                return tv;
              }
            }
          )[0];
        }

        if (element.tipo_servicio == 2) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_equipos.filter(
            async (item) => {
              if (item.numeracion == element.tipo_vh_equi) {
                return item;
              }
            }
          )[0];
        } 
      });
    },*/

    async addDetSolicitud() {
      await this.limpiarDetSolicitud();
      this.detSolicitud.cs_solicitud_interna_id = this.$parent.form.id;
      this.estado_det_solicitud = 1;
    },

    async cancelDetSolicitud() {
      this.estado_det_solicitud = -1;
    },

    async saveDetSolicitud() {
      let me = this;
      if (me.detSolicitud.tipo_servicio_obj.numeracion == 1) {
        if (me.detSolicitud.tipo_vh_equi_obj.tipo == "tv") {
          me.detSolicitud.tipo_vh_equi = me.detSolicitud.tipo_vh_equi_obj.id;
          me.detSolicitud.tipo_remolque = null;
        } else if (me.detSolicitud.tipo_vh_equi_obj.tipo == "tr") {
          me.detSolicitud.tipo_vh_equi = 1;
          me.detSolicitud.tipo_remolque = me.detSolicitud.tipo_vh_equi_obj.id;
        }
      } else {
        me.detSolicitud.tipo_vh_equi =
          me.detSolicitud.tipo_vh_equi_obj.numeracion;
        me.detSolicitud.tipo_remolque = null;
      }
      me.detSolicitud.tipo_servicio =
        me.detSolicitud.tipo_servicio_obj.numeracion;
      me.detSolicitud.turno = me.detSolicitud.turno_obj.numeracion;
      me.detSolicitudes.push(me.detSolicitud);
      me.estado_det_solicitud = -1;
    },

    async saveDetSolicitudTotal() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/asignacionesSolicitudesInternas/detSolicitudesInternas",
        data: {
          cs_solicitud_interna_id: this.$parent.form.id,
          presupuesto_verificado: this.$parent.form.presupuesto_verificado,
          detSolicitudes: this.detSolicitudes,
        },
      })
        .then(async (response) => {
          this.$parent.cargando = false;
          await this.getDetSolicitudes();
          this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async editDetSolicitud(index) {
      this.id_actualizar_det = index;
      this.estado_det_solicitud = 2;

      if (this.detSolicitudes[index].tipo_servicio == 1) {
        if (this.detSolicitudes[index].tipo_remolque !== null) {
          this.detSolicitudes[
            index
          ].tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.find(
            (item) =>
              item.tipo_vehi == this.detSolicitudes[index].tipo_vh_equi &&
              item.id == this.detSolicitudes[index].tipo_remolque &&
              item.tipo == "tr"
          );
        } else {
          this.detSolicitudes[
            index
          ].tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.find(
            (item) =>
              item.tipo_vehi == this.detSolicitudes[index].tipo_vh_equi &&
              item.tipo == "tv"
          );
        }
      }

      if (this.detSolicitudes[index].tipo_servicio == 2) {
        this.detSolicitudes[
          index
        ].tipo_vh_equi_obj = this.listasForms.tipos_equipos.find(
          (item) => item.numeracion == this.detSolicitudes[index].tipo_vh_equi
        );
      }

      this.detSolicitudes[
        index
      ].tipo_servicio_obj = this.listasForms.tipos_servicios.find(
        (item) => item.numeracion == this.detSolicitudes[index].tipo_servicio
      );

      this.detSolicitudes[index].turno_obj = this.listasForms.turnos.find(
        (item) => item.numeracion == this.detSolicitudes[index].tipo_servicio
      );

      this.detSolicitud = { ...this.detSolicitudes[index] };
    },

    async saveUpdateDetSolicitud(index) {
      if (this.detSolicitud.tipo_servicio_obj.numeracion == 1) {
        if (this.detSolicitud.tipo_vh_equi_obj.tipo == "tv") {
          this.detSolicitud.tipo_vh_equi = this.detSolicitud.tipo_vh_equi_obj.id;
          this.detSolicitud.tipo_remolque = null;
        } else if (this.detSolicitud.tipo_vh_equi_obj.tipo == "tr") {
          this.detSolicitud.tipo_vh_equi = 1;
          this.detSolicitud.tipo_remolque = this.detSolicitud.tipo_vh_equi_obj.id;
        }

        //this.detSolicitud.tipo_vh_equi = this.detSolicitud.tipo_vh_equi_obj.id;
      } else {
        this.detSolicitud.tipo_vh_equi = this.detSolicitud.tipo_vh_equi_obj.numeracion;
        this.detSolicitud.tipo_remolque = null;
      }

      this.detSolicitud.tipo_servicio = this.detSolicitud.tipo_servicio_obj.numeracion;
      this.detSolicitud.turno = this.detSolicitud.turno_obj.numeracion;
      this.detSolicitud.estado = 1;
      this.detSolicitudes[index] = this.detSolicitud;
      this.estado_det_solicitud = -1;
      this.id_actualizar_det = -1;

      // Se valida si todos los campos estan diligenciados del detalle
      this.validaSave = true;
      this.detSolicitudes.forEach((det) => {
        if (!det.empresa_id || !det.fecha_ini || !det.fecha_fin) {
          this.validaSave = false;
        }
      });
    },

    async limpiarDetSolicitud() {
      this.detSolicitud = {
        id: null,
        cs_solicitud_interna_id: null,
        tipo_servicio: null,
        tipo_servicio_obj: null,
        tipo_vh_equi: null,
        tipo_vh_equi_obj: null,
        turno_obj: null,
        turno: null,
        cantidad_vh_equi: null,
        tiempo_serv: null,
        descripcion_material: null,
        cantidad_material: null,
        largo: null,
        ancho: null,
        alto: null,
        peso: null,
        radio_izaje: null,
        observaciones: null,
        empresa: null,
        empresa_id: null,
      };
    },

    async getTurnos() {
      await axios.get("/api/lista/188").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },
  },

  async mounted() {
    await this.getTipoVehiculos();
    await this.getTipoEquipo();
    await this.getTipoServicio();
    await this.getEmpresas();
    await this.getTurnos();
  },
};
</script>
